import { Box, useColorModeValue, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import DeleteAccountTab from './components/deleteAccount';
import AssignMeetingsTab from './components/assignMeetings';

import ManageCalendars from './components/manageCalendars';

const Settings = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} w={'75%'} >
      <Tabs variant='unstyled' orientation='vertical' aria-orientation='vertical' h='100vh' pb={'30px'} ml={'10px'}>
        <TabList w={'25%'} minH={'500px'} minW={'250px'} pt={'10px'} borderColor='blackAlpha.300' borderRightWidth='1px'>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} borderTopLeftRadius='16px'>Unassigned meetings</Tab>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} >Delete account</Tab>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} >Manage calendars</Tab>
        </TabList>
        <TabPanels>
          <TabPanel py={'0px'}>
            <AssignMeetingsTab />
          </TabPanel>
          <TabPanel py={'0px'}>
            <DeleteAccountTab />
          </TabPanel>
          <TabPanel py={'0px'}>
            <ManageCalendars />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box >
  );
}

export default Settings;
